import TicketShop from "components/TicketShop";
import React from "react";
import Helmet from "react-helmet";

export default function EventsFeaturedPage(p) {
  const title = "Featured Events | Manchester Students Group";
  const description =
    "Our handpicked selection of the best nights out Manchester has to offer!";

  return (
    <main className="event-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <h1>Featured Events</h1>
      <h3>
        Our handpicked selection of the best nights out Manchester has to offer!
      </h3>
      <div>
        <TicketShop
          scriptString={`
            <script
              src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js"
              data-fixr-shop-id="9327a4fc-5c1c-47e3-8068-d99227ba2081"
              data-fixr-theme="dark"
            ></script>
          `}
        />
      </div>
    </main>
  );
}
