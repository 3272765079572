import React, { useEffect, useRef, useState } from "react";

const TicketShop = ({ scriptString }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (scriptString) {
      const parser = new DOMParser();
      try {
        const scriptEl = parser
          .parseFromString(scriptString, "text/html")
          .getElementsByTagName("script")[0];

        const src = scriptEl.getAttribute("src");
        const { dataset } = scriptEl;

        const script = document.createElement("script");

        for (var d in dataset) {
          script.dataset[d] = dataset[d];
        }

        script.src = src;
        containerRef.current.replaceChildren(script);
      } catch (e) {
        setError(true);
      }
    }
  }, [scriptString]);

  return (
    <div ref={containerRef}>
      {error && (
        <div style={{ border: "1px solid red", padding: "32px" }}>
          Could not parse ticket shop tag. Please check embed code.
        </div>
      )}
    </div>
  );
};

export default TicketShop;
